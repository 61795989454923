import React from "react";
import styled from "styled-components";
import theme from "../../util/theme";

interface StepProps {
  numSteps: number;
  currentStep: number;
}

const StepIndicator: React.FC<StepProps> = ({ numSteps, currentStep }) => {
  return (
    <StepContainer>
      {Array.from({ length: numSteps }, (_, index) => (
        <React.Fragment key={index}>
          <StepCircle completed={index + 1 < currentStep} active={index + 1 <= currentStep} current={index + 1 === currentStep}>
            {index + 1}
          </StepCircle>
          {index < numSteps - 1 && <StepLine active={index + 1 < currentStep} />}
        </React.Fragment>
      ))}
    </StepContainer>
  );
};

const StepContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: -2px;
`;

const StepCircle = styled.div<{ completed: boolean; active: boolean; current: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${props => props.current ? "48px" : "32px"};
  height: ${props => props.current ? "48px" : "32px"};
  transition: all 0.3s ease;
  border-radius: 50%;

  background-color: ${props => props.completed ? theme.PRIMARY_COLOR : props.active ? theme.LIME : theme.LIGHT_GREY};
  color: ${props => props.completed ? theme.WHITE_COLOR : props.active ? theme.BLACK_COLOR : theme.SLATE_COLOR_2};
  font-family: ${theme.SECONDARY_FONT};
  font-weight: 500;
  font-size: ${props => props.active ? "20px" : "12px"};
  z-index: 1;
  
`;

const StepLine = styled.div<{ active: boolean }>`
  width: 32px;
  height: 4px;
  background-color: ${props => props.active ? theme.LIME : theme.LIGHT_GREY};
  /* position: relative; */
  /* left: -2px; */
  /* right: -2px; */
`;

export default StepIndicator;
